import React from "react";
import Layout from "../../components/Layout";
import parse from "html-react-parser";

const styles = require("../../styles/richText.module.scss");

const LegalTemplate = (props) => {
  const {
    pageData: { heading, content, metaData },
  } = props;

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: null,
      }}
    >
      <div className={styles.copyWrap}>
        <h1 className={`${styles.header} h2 `}>{heading}</h1>
        <div className={styles.content}>
          <div className={styles.textBlock}>
            {content ? parse(content) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LegalTemplate;
