import { graphql } from "gatsby";
import React from "react";
import LegalTemplate from "../../templates/Legal/";

const LegalPage = ({ data: { pageData } }) => {
  return <LegalTemplate pageData={pageData} />;
};

export default LegalPage;

export const query = graphql`
  query GetLegalPage($slug: String) {
    pageData: strapiLegalPages(slug: { eq: $slug }) {
      heading
      content
      slug
      metaData {
        pageTitle
        metadescription
      }
    }
  }
`;
